import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from 'components/Layout'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import ProductsPage from '../../components/Shop/ProductsPage/ProductsPage'
import Seo from '../../components/Seo'

export interface IProductsProps {
  data: {
    allShopifyProduct: {
      nodes: {
        handle: string
        title: string
        featuredImage: IGatsbyImageData
        priceRangeV2: {
          maxVariantPrice: {
            amount: number
          }
        }
        options: {
          name: string
          values: string[]
          shopifyId: string
        }[]
      }[]
    }
  }
}

const Products = () => {
  const data = useStaticQuery(query)
  const allProducts = data.allShopifyProduct.nodes
  return (
    <Layout>
      <ProductsPage allProducts={allProducts} />
    </Layout>
  )
}

const query = graphql`
  query {
    allShopifyProduct(filter: { totalInventory: { gt: 0 } }) {
      nodes {
        handle
        title
        featuredImage {
          gatsbyImageData
        }
        priceRangeV2 {
          maxVariantPrice {
            amount
          }
        }
        options {
          name
          values
          shopifyId
        }
      }
    }
  }
`

export default Products

export const Head = () => {
  return (
    <Seo
      title="Shop"
      description="Take part in Xanadu's mission to build quantum computers that are useful and available to people everywhere"
      image="company_10.png"
    />
  )
}
