import * as React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { formatPrice } from '../../../utils/formatPrice'

interface IProductNode {
  handle: string
  title: string
  featuredImage: {
    gatsbyImageData: IGatsbyImageData
  }
  priceRangeV2: {
    maxVariantPrice: {
      amount: number
    }
  }
  options: {
    name: string
    values: string[]
    shopifyId: string
  }[]
}

interface IProductsPageProps {
  allProducts: IProductNode[]
}

const ProductsPage = (props: IProductsPageProps) => {
  const { allProducts } = props
  return (
    <>
      <div className="bg-shop">
        <div className="py-32 inner-container">
          <div className="flex flex-col justify-center w-full h-full lg:w-1/2">
            <div className="flex-none text-white">
              <p className="text-white subtitle opacity-70">{`// Shop`}</p>
              <h1 className="mt-4 h2">Apparel and Accessories</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="standard-page-section">
        <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-4 gap-y-8 px-6">
          {allProducts.map((product: IProductNode) => {
            const colorOptions = product.options
              .filter((option) => option.name === 'Color')[0]
              .values.map((value) => value.toLowerCase())
            return (
              <li key={product.handle}>
                <Link
                  to={`/shop/products/${product.handle}`}
                  className="hover:drop-shadow-lg transition"
                  data-testid="product-link"
                >
                  <div>
                    <div>
                      <GatsbyImage
                        alt={product.title}
                        image={product.featuredImage.gatsbyImageData}
                      />
                    </div>
                    <div className="text-center pt-2">
                      <div>
                        {colorOptions.length > 1 ? (
                          <div className="flex gap-2 justify-center mb-2">
                            {colorOptions.map((colorOption, index) => (
                              <div
                                key={index}
                                className={`w-[20px] h-[20px] rounded-[50%] border border-[#206165] ${
                                  colorOption === 'mauve'
                                    ? `bg-[#C7787B]`
                                    : `bg-${colorOption}`
                                }`}
                              ></div>
                            ))}
                          </div>
                        ) : (
                          <div className="mb-7"></div>
                        )}
                      </div>
                      <p className="text-[#206165] font-bold">
                        {formatPrice(
                          'CAD',
                          product.priceRangeV2.maxVariantPrice.amount
                        )}
                      </p>
                      <p>{product.title}</p>
                    </div>
                  </div>
                </Link>
              </li>
            )
          })}
        </ul>
      </div>
    </>
  )
}

export default ProductsPage
